import { Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import DOMPurify from "dompurify";
import React from "react";
import { templates } from "../../templates";
import { useNotification } from "../Notification";

const CardDetails = ({ template, setDetails, details }) => {
  const { showNotification } = useNotification();

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (value === "") {
      setDetails((prevDetails) => {
        const updatedDetails = { ...prevDetails, [name]: "" };
        sessionStorage.setItem("details", JSON.stringify(updatedDetails));
        return updatedDetails;
      });
      return;
    }

    const sanitizedValue = DOMPurify.sanitize(value);

    if (name === "idNumber") {
      if (!/^[0-9]*$/i.test(sanitizedValue)) {
        showNotification("ID Number must be numeric.", "error");
        return;
      }
      if (sanitizedValue.length > 6) {
        showNotification("ID Number must be at most 6 digits.", "error");
        return;
      }
    }

    if (name === "ownerName") {
      if (!/^[a-z0-9\s.]*$/i.test(sanitizedValue)) {
        showNotification(
          "Owner Name must contain only letters, numbers, and spaces.",
          "error"
        );
        return;
      }
      if (sanitizedValue.length > 100) {
        showNotification("Owner Name must be at most 100 characters.", "error");
        return;
      }
    }

    setDetails((prevDetails) => {
      const updatedDetails = { ...prevDetails, [name]: sanitizedValue };
      sessionStorage.setItem("details", JSON.stringify(updatedDetails));
      return updatedDetails;
    });
  };

  const handleTraitChange = (index, value) => {
    if (value === "") {
      setDetails((prevDetails) => {
        const updatedTraits = { ...prevDetails.traits };
        delete updatedTraits[index];
        const updatedDetails = { ...prevDetails, traits: updatedTraits };
        sessionStorage.setItem("details", JSON.stringify(updatedDetails));
        return updatedDetails;
      });
      return;
    }

    const sanitizedValue = DOMPurify.sanitize(value);
    const updatedTraits = { ...details.traits, [index]: sanitizedValue };
    setDetails((prevDetails) => {
      const updatedDetails = { ...prevDetails, traits: updatedTraits };
      sessionStorage.setItem("details", JSON.stringify(updatedDetails));
      return updatedDetails;
    });
  };

  const handleMultiTraitChange = (index, value) => {
    if (value.length > 2) {
      showNotification("You can select up to 2 traits only.", "error");
      return;
    }
    const sanitizedValues = value.map((val) => DOMPurify.sanitize(val));
    const updatedTraits = { ...details.traits, [index]: sanitizedValues };
    setDetails((prevDetails) => {
      const updatedDetails = { ...prevDetails, traits: updatedTraits };
      sessionStorage.setItem("details", JSON.stringify(updatedDetails));
      return updatedDetails;
    });
  };

  const handleReset = () => {
    const resetDetails = {
      idNumber: "",
      ownerName: "",
      traits: {},
      additionalTraits: {},
    };
    setDetails(resetDetails);
    sessionStorage.setItem("details", JSON.stringify(resetDetails));
  };

  const handleResetTrait = (index) => {
    setDetails((prevDetails) => {
      const updatedTraits = { ...prevDetails.traits };
      delete updatedTraits[index];
      const updatedDetails = { ...prevDetails, traits: updatedTraits };
      sessionStorage.setItem("details", JSON.stringify(updatedDetails));
      return updatedDetails;
    });
  };

  const templateSettings = templates[template];
  const traitLabels = templateSettings[Object.keys(templateSettings)[0]].traits;

  return (
    <Box textAlign={"center"}>
      <Typography variant="p">
        Enter your NFT details & select your traits to display them on the ID
        card.
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
        <TextField
          label="ID Number"
          name="idNumber"
          value={details.idNumber}
          onChange={handleInputChange}
          inputProps={{ maxLength: 6 }}
        />
        <IconButton
          onClick={() =>
            handleInputChange({ target: { name: "idNumber", value: "" } })
          }
          sx={{ ml: 1 }}
          color="secondary"
          size="small"
        >
          <Delete />
        </IconButton>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
        <TextField
          label="Owner Name"
          name="ownerName"
          value={details.ownerName}
          onChange={handleInputChange}
          fullWidth
          inputProps={{ maxLength: 26 }}
        />
        <IconButton
          onClick={() =>
            handleInputChange({ target: { name: "ownerName", value: "" } })
          }
          sx={{ ml: 1 }}
          color="secondary"
          size="small"
        >
          <Delete />
        </IconButton>
      </Box>
      {traitLabels.map((trait, index) => (
        <Box key={index} sx={{ mt: 2 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {trait.isMulti ? (
              <FormControl fullWidth>
                <InputLabel>{trait.name}</InputLabel>
                <Select
                  multiple
                  value={details.traits[index] || []}
                  onChange={(e) =>
                    handleMultiTraitChange(index, e.target.value)
                  }
                  renderValue={(selected) => selected.join(", ")}
                >
                  {trait.options.map((option, optionIndex) => (
                    <MenuItem key={optionIndex} value={option}>
                      <Checkbox
                        checked={
                          details.traits[index]?.includes(option) || false
                        }
                      />
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <TextField
                label={trait.name}
                name={`trait-${index}`}
                select={!!trait.options}
                value={details.traits[index] || ""}
                onChange={(e) => handleTraitChange(index, e.target.value)}
                fullWidth
              >
                {trait.options &&
                  trait.options.map((option, optionIndex) => (
                    <MenuItem key={optionIndex} value={option}>
                      {option}
                    </MenuItem>
                  ))}
              </TextField>
            )}
            <IconButton
              onClick={() => handleResetTrait(index)}
              sx={{ ml: 1 }}
              color="secondary"
              size="small"
            >
              <Delete />
            </IconButton>
          </Box>
        </Box>
      ))}
      <Box
        sx={{
          display: "flex",
          margin: "0 auto",
          justifyContent: "center",
          mt: 1,
        }}
      >
        <Button
          onClick={handleReset}
          sx={{ mt: 2 }}
          variant="contained"
          color="error"
        >
          Reset Form
        </Button>
      </Box>
    </Box>
  );
};

export default CardDetails;
