import GoonsClubSubTemplates from "../templates/GoonsClubSubTemplates";

const sharedTraits = [
  {
    name: "CLUB",
    options: ["Art", "Fashion", "Gaming", "Rare", "Sports"],
  },
  {
    name: "BASE",
    options: [
      "Black",
      "Black Skelly",
      "Blue",
      "Blue Robo",
      "Blue Zombie",
      "Cherry Red",
      "Diamond",
      "Gold",
      "Green",
      "Grey Fur",
      "Pink",
      "Pink Zombie",
      "Rainbow Skelly",
      "Red",
      "Red Skelly",
      "Slime Green",
      "Slime Yellow",
      "Stealth",
      "White",
      "Yellow",
      "Yellow Fur",
      "Yellow Zombie",
    ],
  },
  {
    name: "BODY",
    options: [
      "Abstract",
      "Air Goon",
      "Anti Social Jacket",
      "Arcade",
      "Bandana",
      "Basketball Jersey",
      "Baseball Glove",
      "Baller Tee",
      "Black BNMS",
      "Black Blinged Puffer",
      "Black Gooci Shirt",
      "Black Hoodie",
      "Black Luxe Puffer",
      "Black Shirt",
      "Blinged Black Vest",
      "Blinged Red Vest",
      "Blinged White Vest",
      "BONK",
      "Boxing Hoodie",
      "Box Logo Tee",
      "C&D Shirt",
      "Catoru Hoodie",
      "Coach Jersey",
      "Cream Puff Shirt",
      "Cubed Gold",
      "Cubed Green",
      "Cubed RGB",
      "Datterbayo T-Shirt",
      "Designer Puffer",
      "Diamond X Shirt",
      "Doffy Cloak",
      "Fatality",
      "Faux Vest",
      "Goon Face Tee",
      "Golfing Hoodie",
      "Gold Box Logo",
      "Gold Medal",
      "Gold Mugi Vest",
      "Gold Overalls",
      "Gold Vest",
      "Green X Hoodie",
      "Gym Puffer",
      "Hadouken",
      "Half Life Hoodie",
      "Half Life Shirt",
      "Imposter",
      "Jogo Hoodie",
      "Just Goon It",
      "Kaizoku Shirt",
      "Keep Fashion Weird",
      "Knit Box Logo",
      "Life Is Good",
      "Modern Sayan Vest",
      "Modern Vest",
      "Mugi Vest",
      "OMW Shirt",
      "Oturan T-Shirt",
      "Parachuter",
      "Pepe Shirt",
      "Pink BNMS",
      "Pink Box Logo",
      "Pink Duck Hunt",
      "Pink Overalls",
      "Pink X Hoodie",
      "Plus Ultra Hoodie",
      "Plus Ultra T-Shirt",
      "Racing Jacket",
      "Raincoat",
      "Rare Gym Puffer",
      "Ramen T-Shirt",
      "Red Avante Garde Vest",
      "Red BNMS",
      "Red Gooci Shirt",
      "Red Hoodie",
      "Ruto Puffer",
      "San Shirt",
      "Saita Hoodie",
      "Skate Shirt",
      "Smiley Puffer",
      "Soccer Jersey",
      "Star Shirt",
      "Surfboard",
      "Surfing Shirt",
      "Tankman Shirt",
      "Tie Dye Hoodie",
      "Tri Color Coat",
      "Tsuki Coat",
      "UFO",
      "Victory Royale",
      "Vest & Tie",
      "White Box Logo",
      "White Duck Hunt",
      "White Luxe Puffer",
    ],
  },
  {
    name: "HEAD",
    options: [
      "Abstract",
      "Agoon Us",
      "Airbender",
      "Antenna",
      "Baseball Glove",
      "Basketball Beanie",
      "Big Putts",
      "Black Candy Hair",
      "Black Dreads",
      "Black Plushie",
      "Black Spikes",
      "Blue Adler",
      "Blue Beanie",
      "Blue Dreads",
      "Blue Hair",
      "B12",
      "Bruh Snapback",
      "Captain",
      "Cartridge",
      "Crown",
      "Cubed Gold",
      "Cubed Green",
      "Diamond Basketball Cap",
      "Diamond Beanie",
      "Diamond Hair",
      "Double Hat",
      "Evil Eye Snapback",
      "F1 Cap",
      "F Off Cap",
      "Folks Snapback",
      "Football Helmet",
      "Goons World",
      "Gold Captain",
      "Gold Crown",
      "Gold Ears",
      "Gold Game Over Beanie",
      "Gold Goons Rare",
      "Gold Halo",
      "Gold Horns",
      "Goonball",
      "Green Beanie",
      "Green Ears",
      "Green Folks Cap",
      "Hyotto Mask",
      "Jogo Volcano",
      "Just Some Goon",
      "JSG Snapback",
      "Leather Bandana",
      "Life Is Goon Cap",
      "Loaded Snapback",
      "Marigoon",
      "Mob100 Haircut",
      "Mohawk",
      "Nick Valentine",
      "Nana Cap",
      "Octane",
      "Peashooter",
      "Pink Dreads",
      "Pink Folks Cap",
      "Pink Plushie",
      "Pokeball",
      "Real Cap",
      "Red Game Over Beanie",
      "Red Headband",
      "Red Muletto",
      "Red Plushie",
      "Red Punk Mohawk",
      "Red Snapback",
      "Red Spikes",
      "Rimuru Slime",
      "RT Snapback",
      "Ruto Hair",
      "Sayajin Pink",
      "Shiba Hat",
      "Skate Cap",
      "Skater Helmet",
      "Smash Cap",
      "Smiley Cap",
      "SOL Soldier",
      "StrawHat",
      "Sunny Beanie",
      "Tanji Hat",
      "Tempest Blue Hair",
      "Tinfoil Hat",
      "TKS Hair",
      "Traffy Hat",
      "Tri Headband",
      "Varsity Cap",
      "Wasted",
      "Yellow Game Over Beanie",
      "Yellow Muletto",
      "Yellow Plushie",
    ],
  },
  {
    name: "EYES",
    options: [
      "Black Eyes",
      "Blue Diamond",
      "Blue Neon Eyes",
      "Bored Black",
      "Bored Gold",
      "Bored Neon Blue",
      "Bored Neon Green",
      "Bored Neon Pink",
      "Bored Neon Red",
      "Bored White",
      "Diamond X Eyes",
      "Gold Eyes",
      "Gold X Eyes",
      "Green Neon Eyes",
      "Heart Bound",
      "Orange Neon Eyes",
      "Orange Orbs",
      "Pink Neon Eyes",
      "Pink Neon X",
      "Purple Neon Eyes",
      "Rainbow Neon Eyes",
      "Red Neon Eyes",
      "Rose Neon Eyes",
      "Translucent Eyes",
      "White Eyes",
      "White Neon Eyes",
      "Wicked",
      "X Eyes",
    ],
  },
  {
    name: "MOUTH",
    options: [
      "Big Smile",
      "Blasé",
      "Black Skull",
      "Gold Grunt",
      "Grunt",
      "Kunai",
      "Pink Bubbles",
      "Sigh",
      "Skull",
      "Slime",
      "Smirk",
      "Sulky",
      "Tongue Out",
      "Whistle",
      "White Smoke",
    ],
  },
  {
    name: "EYEWEAR",
    options: [
      "Art Nouns",
      "Art Shades",
      "Balenciagoon",
      "Black Clout Shades",
      "Black Wayfarers",
      "Diamond Inflated Shades",
      "Doffy Shades",
      "Gold Brooks",
      "Gold Inflated Shades",
      "Gold Visor",
      "Goon 64",
      "Heart Eyes",
      "Mesh Shades",
      "Neon Skelly Frames",
      "Pink Bittersweet",
      "Pink Blinks",
      "Pixel Inflated Shades",
      "Red Brooks",
      "Red Visor",
      "Skelly Frames",
      "Skelly Slime Frames",
      "Ushi Golds",
      "Ushi Shades",
      "Wayfarers",
      "White Clout Shades",
      "White Inflated Shades",
      "XO Glasses",
      "Yellow Bittersweet",
      "Yellow Inflated Shades",
    ],
  },
  {
    name: "EXTRAS",
    options: [
      "Black Squares",
      "Cherry Blossom",
      "Demon Fruit Earrings",
      "Diamond Squares",
      "Dual Gold Earrings",
      "Dangles",
      "Energy Field",
      "Gold Earring",
      "Gold Squares",
      "Ichimonji",
      "Pink Earring",
      "Potaras",
      "Red Earring",
      "Red Squares",
      "Samehada",
      "Samehada Diamond",
      "Side Earring",
      "Smoke Valley",
      "Tag",
      "Trunks Sword",
      "Yellow Earring",
    ],
  },
];

const GoonsClub = {
  dark: {
    ...GoonsClubSubTemplates.default,
    collectionName: "Goons Club: 3D",
    idPrefix: "GOON #",
    qrCode: "https://www.goonsclub.com",
    traitsLabel: "TRAITS",
    traits: sharedTraits,
  },
  light: {
    ...GoonsClubSubTemplates.lightVariant,
    collectionName: "Goons Club: 3D",
    idPrefix: "GOON #",
    qrCode: "https://www.goonsclub.com",
    traitsLabel: "TRAITS",
    traits: sharedTraits,
  },
};

export default GoonsClub;
