import AKCBSubTemplates from "../templates/AKCBSubTemplates";

const sharedTraits = [
  // Traits list
  {
    name: "BEASTHOOD",
    options: [
      "Beat Street",
      "Bullhattan District",
      "Champions Court",
      "Cloudy Gardens",
      "Concrete Jungle",
      "Cortado Cafe",
      "Faded Heights",
      "Fort Flex",
      "Gaia Grove",
      "L33t Labs",
      "Michelin Markets",
      "Moo Moo Meadows",
      "Museum Of Beast Arts",
      "Nowhere Parks",
      "Ramone Alley",
      "Studio 666",
      "The Odeon",
      "Trusty Lake Estates",
      "Wagmi Way",
    ],
  },
  {
    name: "BALACLAVA",
    options: [
      "Ape",
      "Beagle",
      "Brown & Cream",
      "Bull",
      "Cat",
      "Clown",
      "Cocker Spaniel",
      "Custom Female Hippies Tan Panda Balaclava",
      "Deer",
      "Hare",
      "Hyena",
      "Koala",
      "Lopped-Ear Rabbit",
      "Medieval",
      "Original",
      "Panda",
      "Piglet",
      "Pooch",
      "Raccoon",
      "Robot",
      "Sheep",
      "Skull",
      "Squirrel",
      "Wolf",
    ],
  },
  {
    name: "BALACLAVA STYLE",
    options: [
      "Bag",
      "Battle Worn Steel",
      "Battle Worn White",
      "Black",
      " Black & Gray",
      "Black & Steel",
      "Blue & Beige",
      "Bones",
      "Brown & Cream",
      "Charcoal & Gray",
      "Chestnut",
      "Copper",
      "Cream Yellow",
      "Creme Ecru",
      "Diamonds",
      "Distressed Black",
      "Distressed Black & White",
      "Ecru",
      "Gold",
      "Gray",
      "Gray & Black",
      "Green",
      "Green Metal",
      "Leopard",
      "Light Brown",
      "Navy Blue",
      "Olive",
      "Pink",
      "Psychedelic",
      "Red",
      "Red Metal",
      "Rusty Teal",
      "Sage Green",
      "Silver",
      "Skull Print",
      "Steel",
      "White",
      "White & Black",
      "White & Gray",
    ],
  },
  {
    name: "RARE",
    options: [
      "'BANG'",
      "Alien",
      "Angel",
      "Anime Ninja",
      "Baby Blue",
      "Banana",
      "Beast Guard",
      "Beast Prime",
      "Boxer",
      "Cheerleader",
      "Clown",
      "Convict",
      "Crash Dummy",
      "Cyclops",
      "Delivery Man",
      "Delivery Woman",
      "Demon",
      "Demon Lord",
      "Detective",
      "Diamond",
      "Dragon",
      "Drip",
      "Elf",
      "Fairy",
      "Farmer",
      "Genie",
      "Gloves of Fury",
      "Gnome",
      "Godfather",
      "Gold",
      "Goth",
      "Grim Reaper",
      "Henchman",
      "Hitman",
      "King",
      "Kitsune",
      "Knight",
      "Kumbaya",
      "Lady Angel",
      "Lady Bones",
      "Lady Zombie",
      "Lil' Chill",
      "Lord Angel",
      "Lord Bones",
      "Lord Zombie",
      "Lost Diver",
      "Low Key B",
      "Mechanic",
      "Medusa",
      "Miner",
      "Mummy",
      "Ninja",
      "No Signal",
      "Present",
      "Princess",
      "Quarterback",
      "Samurai",
      "Santa",
      "Screen Savior",
      "Shaykhah",
      "Sheikh",
      "Spa Day",
      "Spartan",
      "Superstar",
      "Unicorn",
      "Unicorn Star",
      "Up-Town",
      "Vampire",
      "Wanderer",
      "WiFi Hopper",
      "Witch",
      "Wizard",
      "Yeti",
    ],
  },
  {
    name: "DNA",
    options: ["Angel", "Bones", "Human", "Zombie"],
  },
  {
    name: "EYES",
    options: [
      "Bold Eyelashes",
      "Bold Eyeshadow",
      "Dazed Pupils",
      "Fox Eyelashes",
      "Human Pupils",
      "Lizard Pupils",
      "Long Eyelashes",
      "Rainbow Eyeshadow",
      "Sharp Eyelashes",
      "Smoky Eyeshadow",
      "Starry Eyeshadow",
      "Toon Pupils",
      "Vibrant Eyeshadow",
      "Wavy Pupils",
    ],
  },
  {
    name: "HEADWEAR",
    options: [
      "3D Glasses",
      "Aviator Glasses",
      "Babushka Scarf",
      "Backward Six-Panel Cap",
      "Backwards Hat",
      "Backwards Trucker Hat",
      "Bandana",
      "Bandana Bandit",
      "Bandana Head Scarf",
      "Bandana Tied-Front",
      "Baseball Cap & Durag",
      "Beanie",
      "Boater Hat",
      "Bucket Hat",
      "Chef Hat",
      "Chunky Glasses",
      "Cowboy Hat",
      "Cowboy Hat & Durag",
      "Crown",
      "Durag",
      "Flat Cap",
      "Flower Crown",
      "Football Helmet",
      "Fume Mask",
      "Gas Mask",
      "Geometric Glasses",
      "Hair Bow",
      "Halo",
      "Headband",
      "Headphones",
      "Heart-Shaped Glasses",
      "Hockey Mask",
      "Pantos Glasses",
      "Performance Glasses",
      "Phantom",
      "Pixel Glasses",
      "Plague Doctor Mask",
      "Pom Pom Beanie",
      "Rectangle Glasses",
      "Round Glasses",
      "Sabertooth Mask",
      "Six-Panel Cap",
      "Ski Goggles",
      "Spartan Helmet",
      "Spiked Crown",
      "Trucker Hat",
    ],
  },
  {
    name: "TOP",
    options: [
      "Ape Suit",
      "Apron & T-Shirt",
      "Astronaut Top",
      "Baby Doll Top",
      "Basketball Singlet",
      "Bathrobe",
      "Biker Jacket",
      "Biker Jacket & Turtleneck",
      "Blazer",
      "Blouse",
      "Boho Coat",
      "Boho Shirt",
      "Bomber Jacket",
      "Bullet-Proof Vest",
      "Business Shirt",
      "Cape",
      "Cardigan",
      "Cargidan & Shirt",
      "Chefs Uniform",
      "Corduroy Blazer",
      " Crop Top",
      "Denim Jacket",
      "Denim Vest",
      "Distressed Sweater",
      "Flannel Shirt",
      "Football Shirt",
      "Hawaiian Shirt",
      "Hoodie",
      "Hoodie & Backpack",
      "Hoodie & Utility Vest",
      "Hoodie Charcoal",
      "Hoodie Navy",
      "Hoodie Off White",
      "Jacket",
      "Knit Sweater & Shirt",
      "Lab Coat",
      "Layer T-Shirt",
      "Leather Jacket",
      "Leather Vest",
      "Linen Top",
      "Long-Sleeve Top",
      "Loose Hoodie",
      "Loose Hoodie Up",
      "Loose Singlet",
      "Loose Sweater",
      "Loose T-Shirt",
      "Mesh Singlet",
      "Mesh Top",
      "Open Shirt",
      "Open Shirt & Turtleneck",
      "Overall",
      "Overcoat",
      "Peacoat",
      "Plaid Shirt & Backpack",
      "Polo Shirt",
      "Puffer Jacket",
      "Puffer Parka",
      "Puffer Vest",
      "Pyjama Shirt",
      "Quilted Bomber",
      "Raglan Tee",
      "Rain Jacket",
      "Robot Suit",
      "Ruana",
      "Satin Shirt",
      "Sherpa Jacket",
      "Shirt & Suspender",
      "Shirt & Suspenders",
      "Shirt & Sweater",
      "Shirt & Tie",
      "Shirt Dress",
      "Short-Sleeve Shirt",
      "Short-Sleeve Shirt & Napkin",
      "Silk Shirt",
      "Singlet",
      "Suit Jacket",
      "Suit Jacket & Waistcoat",
      "Sweater",
      "Sweater & Blouse",
      "Sweater Vest",
      "Sweater Vest & Blouse",
      "Sweater Vest & Shirt",
      "T-Shirt",
      "T-Shirt & Napkin",
      "T-Shirt & Rocket",
      "T-Shirt & Suspenders",
      "Tech Jacket",
      "Tie-Dye Hoodie",
      "Tie-Dye T-Shirt",
      "Toon Shorts",
      "Tracksuit",
      "Trench Coat",
      "Trucker Jacket",
      "Utility Jacket",
      "Utility Vest & Top",
      "Varsity Jacket",
      "Work Jacket",
      "Work Shirt",
    ],
  },
  {
    name: "BOTTOM",
    options: [
      "A-Line Dress",
      "A-Line Skirt",
      "Astronaut Bottom",
      "Baby Doll Dress",
      "Berry",
      " Black",
      "Black Latex Pants",
      "Black Pants",
      "Box Pleats",
      "Brown",
      "Brown Pants",
      "Canary Yellow",
      "Cargo Pants",
      "Corduroy Pants",
      "Creme Ecru",
      "Dress",
      "Flare Skirt",
      "Gray",
      "High-Rise Shorts",
      "Jeans",
      "Jogger Pants & Graphic",
      "Leather Pants",
      "Light Blue",
      "Loose Shorts",
      "Mini Skirt",
      "Navy Blue & White Stars",
      "Overall Dress",
      "Pants",
      "Pleated Skirt",
      "Red & Black",
      "Red Latex Pants",
      "Ripped Jeans",
      "Robot Suit",
      "Rolled-Up Jeans",
      "Rolled-Up Pants",
      "Shirt Dress",
      "Shorts",
      "Skirt",
      "Suit Pants",
      "Tech Joggers",
      "Tech Pants",
      "White",
      "White & Blue Stripes",
      "White & Burnt",
      "White & Dirty",
      "White & Orange Stripes",
      "Wrap-Around Skirt",
    ],
  },
  {
    name: "BOOTS",
    options: [
      "Boots",
      "Casual Sneakers",
      "Clogs",
      "Cowboy Boots",
      "Desert Boots",
      "Formal Shoe",
      "Gumboots",
      "High Boots",
      "High-Top Sneakers",
      "Hiking Boots",
      "Shell-Toe Sneakers",
      "Slides",
      "Slippers",
      "Sock Sneakers",
      "Tech Sneakers",
      "Utility Boots",
    ],
  },
  {
    name: "ACCESSORIES",
    //addItem: true,
    isMulti: true,
    options: [
      "80's Cellular Phone",
      "Acoustic Guitar",
      "Air Horn",
      "Apple",
      "Axe",
      "Balloon",
      "Baseball",
      "Basketball",
      "Bat",
      "Bat Wood",
      "Beads",
      "Belt & Chain",
      "Bitcoin",
      "Black Ceramic Watch",
      "Blue Glass Flask",
      "Boba Tea",
      "Bomb",
      "Book",
      "Boombox",
      "Bow Tie",
      "Burger",
      "Calculator",
      "Camera",
      " Cereal",
      "Chain",
      "Champions Cup",
      "Chunky Gold Chain",
      "Croquet",
      "Crowbar",
      "Cuban Chain Silver",
      "Daisy",
      "Donut",
      "Dumbell",
      "Electric Guitar",
      "Ethereum",
      "Fanny Pack",
      "Flying V Guitar",
      "Football",
      "Game Controller",
      "Gemstone",
      "Gold Bars",
      "Gold Necklace",
      "Gold Watch",
      "Green Glass Flask",
      "Hand Mirror",
      "Handbag",
      "Heavy Chain",
      "Heavy Chain Necklace",
      "Ice Cream",
      "Laptop",
      "Lasso",
      "Mallet",
      "Microphone",
      "Money Bag",
      "Money Stacks",
      "Mug",
      "Nanchaku",
      "Pearls",
      "Picnic Basket",
      "Polaroid",
      "Purple Glass Flask",
      "Quartz Pendant",
      "Rugby Ball",
      "Shopping Bag",
      "Sign",
      "Silver Chain",
      "Skateboard",
      "Sling Bag",
      "Soda",
      "Spatula",
      "Spatula & Fries",
      "Spiked Wrist Bands",
      "Sports Bag",
      "Spray Can",
      "Suitcase",
      "Surfboard",
      "Tennis Racket",
      "Thumbs Up",
      "Toon Dumbell",
      "Two Thumbs Up",
      "Wallet Chain",
      "Watch",
    ],
  },
];
const AKCB = {
  dark: {
    ...AKCBSubTemplates.default,
    collectionName: "a Kid called Beast",
    idPrefix: "BEAST #",
    qrCode: "https://www.akidcalledbeast.com",
    traitsLabel: "TRAITS",
    traits: sharedTraits,
  },
  light: {
    ...AKCBSubTemplates.lightVariant,
    collectionName: "a Kid called Beast",
    idPrefix: "BEAST #",
    qrCode: "https://www.akidcalledbeast.com",
    traitsLabel: "TRAITS",
    traits: sharedTraits,
  },
};

export default AKCB;
