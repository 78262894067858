import { Box, Typography } from "@mui/material";
import React from "react";
import "../styles/Footer.css"; // Updated import path

const Footer = ({ themeMode }) => {
  return (
    <Box className={`footer footer-${themeMode}`}>
      <Typography variant="body2">
        ⚡powered by{" "}
        <a
          href="https://www.sqweesqwad.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          plushie gang
        </a>
        ⚡
      </Typography>
    </Box>
  );
};

export default Footer;
