const AKCBSubTemplates = {
  default: {
    templateName: "Dark Mode",
    collectionLogo: "/images/akcb-logo-dark.svg",
    collectionBackground: "/images/akcb-dark.jpg",
    backgroundZoom: 1.4,
    backgroundBlur: 5,
    cardBackgroundColor: "#000",
    cardBackgroundImage: "/images/card-bg-dark.png",
    cardFillColor: "rgba(0,0,0,1.6)",
    textColor: "#fff",
    fontFamily: "Arial",
    overlayGradient: {
      type: "linear",
      colors: ["rgba(0,0,0,0)", "rgba(0,0,0,.5)"],
      coords: [0, 0, 0, 1],
    },
  },
  lightVariant: {
    templateName: "Light Mode",
    collectionLogo: "/images/akcb-logo-light.svg",
    collectionBackground: "/images/akcb-light.jpg",
    backgroundZoom: 1.8,
    backgroundBlur: 5,
    cardBackgroundColor: "#fff",
    cardBackgroundImage: "/images/card-bg-light.png",
    cardFillColor: "rgba(0,0,0,0.3)",
    textColor: "#000",
    fontFamily: "Arial",
    overlayGradient: {
      type: "linear",
      colors: ["rgba(255,255,255,0)", "rgba(255,255,255,0.6)"],
      coords: [0, 0, 0, 1],
    },
  },
  // Add more sub-templates as needed
};

export default AKCBSubTemplates;
