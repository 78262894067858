import Konva from "konva";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { Layer, Stage } from "react-konva";
import { templates } from "../templates";
import AKCBSubTemplates from "../templates/AKCBSubTemplates";
import GoonsClubSubTemplates from "../templates/GoonsClubSubTemplates";

const DEFAULT_IMAGE_URL = "/images/akcb-light.jpg";
const SCENE_WIDTH = 1080;
const SCENE_HEIGHT = 1080;
const CARD_WIDTH = 440;
const CARD_HEIGHT = 720;
const DEFAULT_QR_URL = "";

const Card = forwardRef((props, ref) => {
  const {
    details,
    template,
    subTemplate,
    uploadedImage,
    useTemplateBackground,
    templateMode,
    useCustomQRCode,
    customQRCodeURL,
  } = props;
  const stageRef = useRef(null);
  const containerRef = useRef(null);
  const backgroundLayerRef = useRef(null);
  const imagesLayerRef = useRef(null);
  const textLayerRef = useRef(null);
  const qrCodeLayerRef = useRef(null);

  useImperativeHandle(ref, () => ({
    getStage: () => stageRef.current,
    saveImage: saveFullResolutionImage,
  }));

  const fitStageIntoParentContainer = () => {
    const container = containerRef.current;
    if (container) {
      const containerWidth = container.offsetWidth;
      const scale = containerWidth / SCENE_WIDTH;

      stageRef.current.width(SCENE_WIDTH * scale);
      stageRef.current.height(SCENE_HEIGHT * scale);
      stageRef.current.scale({ x: scale, y: scale });
      stageRef.current.batchDraw();
    }
  };

  useEffect(() => {
    fitStageIntoParentContainer();
    window.addEventListener("resize", fitStageIntoParentContainer);
    return () => {
      window.removeEventListener("resize", fitStageIntoParentContainer);
    };
  }, []);

  const saveFullResolutionImage = () => {
    const stage = stageRef.current;
    stage.width(SCENE_WIDTH);
    stage.height(SCENE_HEIGHT);
    stage.scale({ x: 1, y: 1 });
    stage.batchDraw();

    const dataURL = stage.toDataURL({ pixelRatio: 2 });
    fitStageIntoParentContainer();

    return dataURL;
  };

  const templateSettings = useMemo(() => {
    const getSubTemplates = (template) => {
      switch (template) {
        case "AKCB":
          return AKCBSubTemplates;
        case "GoonsClub":
          return GoonsClubSubTemplates;
        default:
          return {};
      }
    };
    return {
      ...templates[template][templateMode],
      ...getSubTemplates(template)[subTemplate],
    };
  }, [template, templateMode, subTemplate]);

  const loadImage = (url) => {
    return new Promise((resolve, reject) => {
      const img = new window.Image();
      img.src = url;
      img.crossOrigin = "anonymous";
      img.onload = () => resolve(img);
      img.onerror = () => reject(new Error(`Failed to load image: ${url}`));
    });
  };

  const calculateFontSize = useCallback(
    (text, maxWidth, initialFontSize = 12) => {
      const context = document.createElement("canvas").getContext("2d");
      let fontSize = initialFontSize;
      context.font = `${fontSize}px ${templateSettings.fontFamily}`;

      while (context.measureText(text).width > maxWidth && fontSize > 8) {
        fontSize -= 3.3;
        context.font = `${fontSize}px ${templateSettings.fontFamily}`;
      }

      return fontSize;
    },
    [templateSettings.fontFamily]
  );

  const createTextElement = useCallback(
    (config) => {
      return new Konva.Text({
        ...config,
        fill: templateSettings.textColor,
        fontFamily: templateSettings.fontFamily,
      });
    },
    [templateSettings.textColor, templateSettings.fontFamily]
  );

  const renderCanvas = useCallback(async () => {
    const stage = stageRef.current;
    const backgroundLayer = backgroundLayerRef.current;
    const imagesLayer = imagesLayerRef.current;
    const textLayer = textLayerRef.current;
    const qrCodeLayer = qrCodeLayerRef.current;

    if (
      !stage ||
      !backgroundLayer ||
      !imagesLayer ||
      !textLayer ||
      !qrCodeLayer
    )
      return;

    backgroundLayer.destroyChildren();
    imagesLayer.destroyChildren();
    textLayer.destroyChildren();
    qrCodeLayer.destroyChildren();

    const backgroundImageURL =
      useTemplateBackground && templateSettings.collectionBackground
        ? templateSettings.collectionBackground
        : uploadedImage || DEFAULT_IMAGE_URL;

    try {
      const backgroundImage = await loadImage(backgroundImageURL);
      const bg = new Konva.Image({
        image: backgroundImage,
        width: SCENE_WIDTH,
        height: SCENE_HEIGHT,
        x: 0,
        y: 0,
        scaleX: templateSettings.backgroundZoom,
        scaleY: templateSettings.backgroundZoom,
        blurRadius: templateSettings.backgroundBlur,
      });
      bg.cache();
      bg.filters([Konva.Filters.Blur]);
      backgroundLayer.add(bg);

      if (templateSettings.overlayGradient) {
        const { colors, coords } = templateSettings.overlayGradient;
        const gradientFill = backgroundLayer
          .getContext()
          .createLinearGradient(
            coords[0] * stage.width(),
            coords[1] * stage.height(),
            coords[2] * stage.width(),
            coords[3] * stage.height()
          );
        colors.forEach((color, index) => {
          gradientFill.addColorStop(index / (colors.length - 1), color);
        });

        const overlayRect = new Konva.Rect({
          width: SCENE_WIDTH,
          height: SCENE_HEIGHT,
          fill: gradientFill,
        });
        backgroundLayer.add(overlayRect);
      }

      const cardLeft = (SCENE_WIDTH - CARD_WIDTH) / 2;
      const cardTop = (SCENE_HEIGHT - CARD_HEIGHT) / 2;

      const cardGroup = new Konva.Group({
        x: cardLeft,
        y: cardTop,
      });

      if (templateSettings.cardBackgroundImage) {
        const cardBgImage = await loadImage(
          templateSettings.cardBackgroundImage
        );
        const cardBg = new Konva.Image({
          image: cardBgImage,
          width: CARD_WIDTH,
          height: CARD_HEIGHT,
          shadowBlur: 30,
          fill: templateSettings.cardBackgroundColor,
          shadowColor: templateSettings.cardFillColor,
          cornerRadius: 15,
        });
        cardGroup.add(cardBg);
      } else {
        const cardBg = new Konva.Rect({
          width: CARD_WIDTH,
          height: CARD_HEIGHT,
          fill: templateSettings.cardFillColor,
          shadowBlur: 30,
          shadowColor: templateSettings.cardFillColor,
          cornerRadius: 15,
        });
        cardGroup.add(cardBg);
      }

      imagesLayer.add(cardGroup);

      const profileImage = uploadedImage || DEFAULT_IMAGE_URL;
      const profileImg = await loadImage(profileImage);
      const profilePic = new Konva.Image({
        image: profileImg,
        x: cardLeft + 20,
        y: cardTop + 20,
        width: 400,
        height: 400,
        cornerRadius: 10,
      });
      imagesLayer.add(profilePic);

      const collectionLogo = await loadImage(templateSettings.collectionLogo);
      const logoImg = new Konva.Image({
        image: collectionLogo,
        x: cardLeft + 30,
        y: cardTop + 30,
        width: 60,
        height: 60,
      });
      imagesLayer.add(logoImg);

      textLayer.add(
        createTextElement({
          text: templateSettings.collectionName,
          x: cardLeft + 30,
          y: cardTop + CARD_HEIGHT - 284,
          fontSize: 16,
          fontStyle: "bold",
        })
      );

      const idNumberLabel = createTextElement({
        text: templateSettings.idPrefix,
        x: cardLeft + 30,
        y: cardTop + CARD_HEIGHT - 265,
        fontSize: 20,
        fontStyle: "bold",
      });
      textLayer.add(idNumberLabel);

      textLayer.add(
        createTextElement({
          text: details.idNumber,
          x: idNumberLabel.x() + idNumberLabel.width() + 1,
          y: idNumberLabel.y() - 3,
          fontSize: 24,
          fontStyle: "italic bold",
        })
      );

      const ownerNameLabel = createTextElement({
        text: "OWNED BY:",
        x: cardLeft + 30,
        y: cardTop + CARD_HEIGHT - 242,
        fontSize: 10,
        fontStyle: "bold",
      });
      textLayer.add(ownerNameLabel);

      const ownerNameFontSize = calculateFontSize(
        details.ownerName,
        CARD_WIDTH - ownerNameLabel.width() - 35,
        18
      );

      textLayer.add(
        createTextElement({
          text: details.ownerName,
          x: ownerNameLabel.x() + ownerNameLabel.width() + 2,
          y: ownerNameLabel.y() - 5,
          fontSize: ownerNameFontSize,
          fontStyle: "italic bold",
        })
      );

      textLayer.add(
        createTextElement({
          text: templateSettings.traitsLabel,
          x: cardLeft + 30,
          y: cardTop + CARD_HEIGHT - 208,
          fontSize: 10,
          fontStyle: "bold",
        })
      );

      const separator = new Konva.Line({
        points: [
          cardLeft + 30,
          cardTop + CARD_HEIGHT - 194,
          cardLeft + CARD_WIDTH - 30,
          cardTop + CARD_HEIGHT - 194,
        ],
        stroke: templateSettings.textColor,
        strokeWidth: 1,
      });
      textLayer.add(separator);

      const traits = Object.entries(details.traits)
        .map(([key, value]) => {
          if (!templateSettings.traits[key]) {
            return null;
          }
          return {
            name: templateSettings.traits[key].name,
            value: Array.isArray(value) ? value.join(", ") : value,
          };
        })
        .filter(Boolean);

      const totalTraits = traits.length;
      const columns = totalTraits > 6 ? 2 : 1;

      let traitTopLeft = cardTop + CARD_HEIGHT - 187;
      let traitTopRight = cardTop + CARD_HEIGHT - 187;

      traits.forEach((trait, index) => {
        if (trait.value) {
          const traitLabel = createTextElement({
            text: trait.name,
            x: cardLeft + (index % columns === 0 ? 35 : CARD_WIDTH / 2 + 0),
            y: index % columns === 0 ? traitTopLeft : traitTopRight,
            fontSize: 10,
            fontStyle: "bold",
          });
          textLayer.add(traitLabel);

          const traitValueFontSize = calculateFontSize(
            trait.value,
            CARD_WIDTH / columns - 35,
            14
          );

          textLayer.add(
            createTextElement({
              text: trait.value,
              x: cardLeft + (index % columns === 0 ? 40 : CARD_WIDTH / 2 + 5),
              y: traitLabel.attrs.y + 12,
              fontSize: traitValueFontSize,
              fontStyle: "italic bold",
            })
          );

          if (index % columns === 0) {
            traitTopLeft += 30;
          } else {
            traitTopRight += 30;
          }
        }
      });

      if (details.additionalTraits) {
        Object.keys(details.additionalTraits).forEach((traitName, index) => {
          const traitTop = traitTopLeft;
          details.additionalTraits[traitName].forEach(
            (traitValue, additionalIndex) => {
              const traitLabel = createTextElement({
                text: `${traitName} (additional)`,
                x:
                  cardLeft + (index % columns === 0 ? 40 : CARD_WIDTH / 2 + 10),
                y:
                  traitTop +
                  (index % columns === 0
                    ? additionalIndex * 30
                    : additionalIndex * 30),
                fontSize: 12,
                fontStyle: "bold",
              });
              textLayer.add(traitLabel);

              const traitValueFontSize = calculateFontSize(
                traitValue,
                CARD_WIDTH / columns - 40,
                16
              );

              textLayer.add(
                createTextElement({
                  text: traitValue,
                  x:
                    cardLeft +
                    (index % columns === 0 ? 44 : CARD_WIDTH / 2 + 16),
                  y: traitLabel.attrs.y + 11,
                  fontSize: traitValueFontSize,
                  fontStyle: "italic bold",
                })
              );

              if (index % columns === 0) {
                traitTopLeft += 30;
              } else {
                traitTopRight += 30;
              }
            }
          );
        });
      }

      const qrCodeURL = useCustomQRCode
        ? customQRCodeURL || DEFAULT_QR_URL
        : templateSettings.qrCode;
      const qrCodeImage = await loadImage(
        `https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(
          qrCodeURL
        )}&size=100x100`
      );
      const qrCode = new Konva.Image({
        image: qrCodeImage,
        x: cardLeft + CARD_WIDTH - 100,
        y: cardTop + CARD_HEIGHT - 280,
        width: 70,
        height: 70,
      });
      qrCodeLayer.add(qrCode);

      stage.batchDraw();
    } catch (error) {
      console.error(error);
    }
  }, [
    details,
    template,
    subTemplate,
    uploadedImage,
    useTemplateBackground,
    templateMode,
    templateSettings,
    calculateFontSize,
    createTextElement,
    useCustomQRCode,
    customQRCodeURL,
  ]);

  useEffect(() => {
    renderCanvas();
  }, [
    useTemplateBackground,
    uploadedImage,
    details,
    template,
    subTemplate,
    templateMode,
    useCustomQRCode,
    customQRCodeURL,
    renderCanvas,
  ]);

  return (
    <div
      id="stage-parent"
      ref={containerRef}
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Stage width={SCENE_WIDTH} height={SCENE_HEIGHT} ref={stageRef}>
        <Layer ref={backgroundLayerRef} />
        <Layer ref={imagesLayerRef} />
        <Layer ref={textLayerRef} />
        <Layer ref={qrCodeLayerRef} />
      </Stage>
    </div>
  );
});

export default Card;
