import Close from "@mui/icons-material/Close";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Box,
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import "../../styles/Sidebar.css";
import CardDetails from "./CardDetails";
import ImageUploader from "./ImageUploader";
import QRCodeGenerator from "./QRCodeGenerator";
import SaveButton from "./SaveButton";
import TemplateSelector from "./TemplateSelector";

const Sidebar = ({
  isOpen,
  toggleSidebar,
  setTemplate,
  setTemplateMode,
  templateMode,
  template,
  subTemplate,
  setSubTemplate,
  useCustomQRCode,
  setUseCustomQRCode,
  customQRCodeURL,
  setCustomQRCodeURL,
  details,
  setDetails,
  uploadedImage,
  setUploadedImage,
  useTemplateBackground,
  setUseTemplateBackground,
  getStage,
  clearDownloadLink,
}) => {
  const [openSections, setOpenSections] = useState({
    imageUploader: false,
    templateSelector: false,
    cardDetails: false,
    qrCodeGenerator: false,
    saveButton: false,
  });

  const handleSectionClick = (section) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={toggleSidebar}
      PaperProps={{ className: "sidebar-drawer" }}
    >
      <Box display="flex" justifyContent="flex-end" p={1}>
        <IconButton onClick={toggleSidebar}>
          <Close />
        </IconButton>
      </Box>
      <List>
        <ListItem button onClick={() => handleSectionClick("imageUploader")}>
          <ListItemText primary="1. Upload Image" className="itemText" />
          {openSections.imageUploader ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openSections.imageUploader} timeout="auto" unmountOnExit>
          <Box className="center-content" p={2}>
            <ImageUploader
              uploadedImage={uploadedImage}
              setUploadedImage={setUploadedImage}
            />
          </Box>
        </Collapse>

        <ListItem button onClick={() => handleSectionClick("templateSelector")}>
          <ListItemText primary="2. Select Template" className="itemText" />
          {openSections.templateSelector ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse
          in={openSections.templateSelector}
          timeout="auto"
          unmountOnExit
        >
          <Box className="center-content" p={2}>
            <TemplateSelector
              setTemplate={setTemplate}
              setTemplateMode={setTemplateMode}
              templateMode={templateMode}
              useTemplateBackground={useTemplateBackground}
              setUseTemplateBackground={setUseTemplateBackground}
              template={template}
              subTemplate={subTemplate}
              setSubTemplate={setSubTemplate}
            />
          </Box>
        </Collapse>

        <ListItem button onClick={() => handleSectionClick("cardDetails")}>
          <ListItemText primary="3. Enter Info" className="itemText" />
          {openSections.cardDetails ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openSections.cardDetails} timeout="auto" unmountOnExit>
          <Box className="center-content" p={2}>
            <CardDetails
              template={template}
              setDetails={setDetails}
              details={details}
            />
          </Box>
        </Collapse>

        <ListItem button onClick={() => handleSectionClick("qrCodeGenerator")}>
          <ListItemText primary="4. QR Code (optional)" className="itemText" />
          {openSections.qrCodeGenerator ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse
          in={openSections.qrCodeGenerator}
          timeout="auto"
          unmountOnExit
        >
          <Box className="center-content" p={2}>
            <QRCodeGenerator
              useCustomQRCode={useCustomQRCode}
              setUseCustomQRCode={setUseCustomQRCode}
              customQRCodeURL={customQRCodeURL}
              setCustomQRCodeURL={setCustomQRCodeURL}
            />
          </Box>
        </Collapse>

        <ListItem button onClick={() => handleSectionClick("saveButton")}>
          <ListItemText primary="5. Save" className="itemText" />
          {openSections.saveButton ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openSections.saveButton} timeout="auto" unmountOnExit>
          <Box p={2}>
            <SaveButton
              getStage={getStage}
              clearDownloadLink={clearDownloadLink}
            />
          </Box>
        </Collapse>
      </List>
    </Drawer>
  );
};

export default Sidebar;
