const GoonsClubSubTemplates = {
  default: {
    templateName: "Dark Mode",
    collectionLogo: "/images/goons-logo-dark.svg",
    collectionBackground: "/images/goons-dark.jpg",
    backgroundZoom: 1.3,
    backgroundBlur: 10,
    cardBackgroundColor: "#333",
    cardBackgroundImage: "/images/card-bg-dark.png",
    cardFillColor: "rgba(0,0,0,1.6)",
    textColor: "#fff",
    fontFamily: "Arial",
    overlayGradient: {
      type: "linear",
      colors: ["rgba(0,0,0,0)", "rgba(0,0,0,0.8)"],
      coords: [0, 0, 0, 1],
    },
  },
  lightVariant: {
    templateName: "Light Mode",
    collectionLogo: "/images/goons-logo-light.svg",
    collectionBackground: "/images/goons-light.jpg",
    backgroundZoom: 1.8,
    backgroundBlur: 5,
    cardBackgroundColor: "#fff",
    cardBackgroundImage: "/images/card-bg-light.png",
    cardFillColor: "rgba(0,0,0,0.4)",
    textColor: "#000",
    fontFamily: "Arial",
    overlayGradient: {
      type: "linear",
      colors: ["rgba(255,255,255,0)", "rgba(255,255,255,0.3)"],
      coords: [0, 0, 0, 1],
    },
  },
  PlushieGang: {
    templateName: "Plushie Gang",
    collectionLogo: "/images/goons-logo-plushie.svg",
    collectionBackground: "/images/goons-light.jpg",
    backgroundZoom: 1.5,
    backgroundBlur: 5,
    cardBackgroundColor: "#FDAEFF",
    cardBackgroundImage: "/images/card-bg-plushie.png",
    cardFillColor: "rgba(0,0,0,0.6)",
    textColor: "#000",
    fontFamily: "Arial",
    overlayGradient: {
      type: "linear",
      colors: ["rgba(255,255,255,0)", "rgba(255,200,230,0.6)"],
      coords: [0, 0, 0, 1],
    },
  },
  // Add more sub-templates as needed
};

export default GoonsClubSubTemplates;
