import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AKCBSubTemplates from "../../templates/AKCBSubTemplates";
import GoonsClubSubTemplates from "../../templates/GoonsClubSubTemplates";

const TemplateSelector = ({
  setTemplate,
  setTemplateMode,
  templateMode,
  useTemplateBackground,
  setUseTemplateBackground,
  template,
  subTemplate,
  setSubTemplate,
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState(template);

  useEffect(() => {
    setSelectedTemplate(template);
  }, [template]);

  const handleTemplateChange = (event, newTemplate) => {
    if (newTemplate !== null) {
      setSelectedTemplate(newTemplate);
      setTemplate(newTemplate);
      setSubTemplate("default");
      sessionStorage.setItem("template", newTemplate);
      sessionStorage.setItem("subTemplate", "default");
    }
  };

  const handleBackgroundToggle = () => {
    setUseTemplateBackground(!useTemplateBackground);
    sessionStorage.setItem(
      "useTemplateBackground",
      JSON.stringify(!useTemplateBackground)
    );
  };

  const handleSubTemplateChange = (event) => {
    const newSubTemplate = event.target.value;
    setSubTemplate(newSubTemplate);
    sessionStorage.setItem("subTemplate", newSubTemplate);
  };

  const getSubTemplates = (template) => {
    switch (template) {
      case "AKCB":
        return AKCBSubTemplates;
      case "GoonsClub":
        return GoonsClubSubTemplates;
      default:
        return {};
    }
  };

  const subTemplates = getSubTemplates(selectedTemplate);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        mb: 2,
      }}
    >
      <Typography variant="p">
        Select a project template and then choose a color mode to customize your
        ID card further.
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center", m: 2 }}>
        <ToggleButtonGroup
          value={selectedTemplate}
          exclusive
          onChange={handleTemplateChange}
          aria-label="template selector"
        >
          <ToggleButton value="AKCB" aria-label="AKCB template">
            AKCB
          </ToggleButton>
          <ToggleButton value="GoonsClub" aria-label="Goons Club template">
            Goons Club
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
        <FormControl fullWidth>
          <InputLabel id="sub-template-select-label">Sub Template</InputLabel>
          <Select
            labelId="sub-template-select-label"
            value={subTemplate}
            onChange={handleSubTemplateChange}
            label="Sub Template"
          >
            {Object.keys(subTemplates).map((key) => (
              <MenuItem key={key} value={key}>
                {subTemplates[key].templateName || key}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={useTemplateBackground}
            onChange={handleBackgroundToggle}
          />
        }
        label="Use Template Background"
      />
    </Box>
  );
};

export default TemplateSelector;
