import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import QRCode from "qrcode.react";
import React, { useEffect, useState } from "react";

const QRCodeGenerator = ({
  useCustomQRCode,
  setUseCustomQRCode,
  customQRCodeURL,
  setCustomQRCodeURL,
}) => {
  const DEFAULT_QR_URL = "";
  const [inputValue, setInputValue] = useState(
    customQRCodeURL || DEFAULT_QR_URL
  );

  const handleCheckboxChange = (event) => {
    const useCustom = event.target.checked;
    setUseCustomQRCode(useCustom);
    if (!inputValue) {
      setInputValue(DEFAULT_QR_URL);
    }
    localStorage.setItem("useCustomQRCode", JSON.stringify(useCustom));
  };

  const handleInputChange = (event) => {
    const url = event.target.value;
    setInputValue(url);
    if (url.trim() === "") {
      setCustomQRCodeURL(DEFAULT_QR_URL);
    } else {
      setCustomQRCodeURL(url);
    }
    localStorage.setItem("customQRCodeURL", url);
  };

  useEffect(() => {
    if (useCustomQRCode && !customQRCodeURL) {
      setCustomQRCodeURL(DEFAULT_QR_URL);
    }
  }, [useCustomQRCode, customQRCodeURL, setCustomQRCodeURL]);

  return (
    <Box p={2} sx={{ maxWidth: 280, margin: "0 auto", textAlign: "center" }}>
      <Typography variant="body1">
        The default QR code directs to the selected project's website. Click the
        checkbox to enter a custom QR code or leave it blank to remove the QR
        code completely.
      </Typography>
      <FormControlLabel
        control={
          <Checkbox checked={useCustomQRCode} onChange={handleCheckboxChange} />
        }
        label="Custom QR Code"
      />
      {useCustomQRCode && (
        <TextField
          label="Enter URL"
          value={inputValue}
          onChange={handleInputChange}
          fullWidth
          sx={{ mt: 2 }}
        />
      )}
      {useCustomQRCode ? (
        <Box mt={2}>
          <QRCode value={inputValue || DEFAULT_QR_URL} />
        </Box>
      ) : (
        <Box mt={2}>
          <QRCode value="" />
        </Box>
      )}
    </Box>
  );
};

export default QRCodeGenerator;
