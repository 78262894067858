import ClearIcon from "@mui/icons-material/Clear";
import { Box, Button, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNotification } from "../Notification";

const defaultImageURL = "/images/akcb-light.jpg";

const ImageUploader = ({ uploadedImage, setUploadedImage }) => {
  const { showNotification } = useNotification();
  const [imageUrl, setImageUrl] = useState(
    sessionStorage.getItem("uploadedImage") || defaultImageURL
  );

  useEffect(() => {
    const storedImage = sessionStorage.getItem("uploadedImage");
    setImageUrl(storedImage || defaultImageURL);
    setUploadedImage(storedImage || defaultImageURL);
  }, [setUploadedImage]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (!file) {
      showNotification("No file selected", "error");
      return;
    }

    if (!file.type.startsWith("image/")) {
      showNotification("Please upload a valid image file.", "error");
      return;
    }

    if (file.size > 10 * 1024 * 1024) {
      showNotification("File is too large. Maximum size is 10MB.", "error");
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      const imageUrl = reader.result;
      setImageUrl(imageUrl);
      setUploadedImage(imageUrl);
      sessionStorage.setItem("uploadedImage", imageUrl);
      showNotification("Image uploaded successfully", "success");
    };
    reader.onerror = (error) => {
      showNotification(`Error reading file: ${error}`, "error");
    };
    reader.readAsDataURL(file);
  };

  const handleClearImage = () => {
    setImageUrl(defaultImageURL);
    setUploadedImage(defaultImageURL);
    sessionStorage.removeItem("uploadedImage");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        mb: 2,
      }}
    >
      <Typography variant="body1">
        Use the button below to upload your PFP. To achieve the best results,
        use a 1080 x 1080 image.
      </Typography>
      <Button variant="contained" component="label" sx={{ mt: 2 }}>
        Upload Image
        <input
          type="file"
          accept="image/*"
          hidden
          onChange={handleImageUpload}
        />
      </Button>
      {imageUrl && (
        <Box mt={2} display="flex" alignItems="center">
          <img
            src={imageUrl}
            alt="Selected"
            style={{
              width: "100px",
              height: "100px",
              objectFit: "cover",
              marginRight: "10px",
              borderRadius: "5px",
              border: "1px solid green",
            }}
          />
          <IconButton onClick={handleClearImage} color="inherit">
            <ClearIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default ImageUploader;
