import { ThemeProvider, createTheme } from "@mui/material/styles";
import React, { useCallback, useEffect, useRef, useState } from "react";
import "./App.css";
import Card from "./components/Card";
import Footer from "./components/Footer";
import Header from "./components/Header";
import ModalComponent from "./components/ModalComponent";
import { NotificationProvider } from "./components/Notification";
import Sidebar from "./components/Sidebar/Sidebar";
import "./styles/variables.css";
import darkTheme from "./theme/darkTheme";
import lightTheme from "./theme/lightTheme";

const initialCardDetails = {
  idNumber: "0000",
  ownerName: "Your Name Here",
  traits: {
    trait1: "Value 1",
    trait2: "Value 2",
  },
  additionalTraits: {},
};

const App = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [themeMode, setThemeMode] = useState(
    sessionStorage.getItem("themeMode") || "dark"
  );
  const [template, setTemplate] = useState(
    sessionStorage.getItem("template") || "AKCB"
  );
  const [templateMode, setTemplateMode] = useState(
    sessionStorage.getItem("templateMode") || "dark"
  );
  const [subTemplate, setSubTemplate] = useState(
    sessionStorage.getItem("subTemplate") || "default"
  );
  const [useCustomQRCode, setUseCustomQRCode] = useState(
    JSON.parse(sessionStorage.getItem("useCustomQRCode")) || false
  );
  const [customQRCodeURL, setCustomQRCodeURL] = useState(
    sessionStorage.getItem("customQRCodeURL") || ""
  );
  const [details, setDetails] = useState(
    JSON.parse(sessionStorage.getItem("details")) || initialCardDetails
  );
  const [uploadedImage, setUploadedImage] = useState(
    sessionStorage.getItem("uploadedImage") || null
  );
  const [useTemplateBackground, setUseTemplateBackground] = useState(
    JSON.parse(sessionStorage.getItem("useTemplateBackground")) || false
  );
  const [open, setOpen] = useState(true); // State for modal
  const cardRef = useRef(null);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleTheme = () => {
    const newThemeMode = themeMode === "dark" ? "light" : "dark";
    setThemeMode(newThemeMode);
    sessionStorage.setItem("themeMode", newThemeMode);
  };

  const theme = createTheme(themeMode === "dark" ? darkTheme : lightTheme);

  useEffect(() => {
    const body = document.body;
    if (themeMode === "dark") {
      body.classList.add("dark-mode");
    } else {
      body.classList.remove("dark-mode");
    }
  }, [themeMode]);

  useEffect(() => {
    sessionStorage.setItem("template", template);
    sessionStorage.setItem("templateMode", templateMode);
    sessionStorage.setItem("subTemplate", subTemplate);
    sessionStorage.setItem("useCustomQRCode", JSON.stringify(useCustomQRCode));
    sessionStorage.setItem("customQRCodeURL", customQRCodeURL);
    sessionStorage.setItem("details", JSON.stringify(details));
    sessionStorage.setItem("uploadedImage", uploadedImage);
    sessionStorage.setItem(
      "useTemplateBackground",
      JSON.stringify(useTemplateBackground)
    );
  }, [
    template,
    templateMode,
    subTemplate,
    useCustomQRCode,
    customQRCodeURL,
    details,
    uploadedImage,
    useTemplateBackground,
  ]);

  const handleClose = () => setOpen(false); // Function to close the modal

  const clearDownloadLink = useCallback(() => {
    if (cardRef.current) {
      cardRef.current.clearDownloadLink();
    }
  }, [cardRef]);

  return (
    <NotificationProvider>
      <ThemeProvider theme={theme}>
        <div className="app-container">
          <Header
            toggleSidebar={toggleSidebar}
            toggleTheme={toggleTheme}
            themeMode={themeMode}
          />
          <div className="content-container">
            <Sidebar
              isOpen={isSidebarOpen}
              toggleSidebar={toggleSidebar}
              setTemplate={setTemplate}
              setTemplateMode={setTemplateMode}
              templateMode={templateMode}
              template={template}
              subTemplate={subTemplate}
              setSubTemplate={setSubTemplate} // Added setSubTemplate back
              useCustomQRCode={useCustomQRCode}
              setUseCustomQRCode={setUseCustomQRCode}
              customQRCodeURL={customQRCodeURL}
              setCustomQRCodeURL={setCustomQRCodeURL}
              details={details}
              setDetails={setDetails}
              uploadedImage={uploadedImage}
              setUploadedImage={setUploadedImage}
              useTemplateBackground={useTemplateBackground}
              setUseTemplateBackground={setUseTemplateBackground}
              getStage={() => cardRef.current} // Pass getStage prop
              clearDownloadLink={clearDownloadLink} // Pass clearDownloadLink prop
            />
            <main className="main-content">
              <div className="canvas-container">
                <Card
                  ref={cardRef}
                  details={details}
                  template={template}
                  subTemplate={subTemplate}
                  useCustomQRCode={useCustomQRCode}
                  customQRCodeURL={customQRCodeURL}
                  uploadedImage={uploadedImage}
                  useTemplateBackground={useTemplateBackground}
                  templateMode={templateMode}
                />
              </div>
            </main>
          </div>
          <Footer themeMode={themeMode} />
        </div>
        <ModalComponent
          className="modal"
          themeMode={themeMode}
          open={open}
          handleClose={handleClose}
        />
      </ThemeProvider>
    </NotificationProvider>
  );
};

export default App;
